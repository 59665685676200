import React, { useMemo } from 'react'

import { msalConfig, loginRequest } from '../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';

import { graphql } from 'gatsby'

import Wrapper from '../components/wrapper'
import AdminPage from '../components/admin-page'

export default ({ data }) =>
{
    const courseInstanceId = data.site.siteMetadata.courseInstanceId

    const courseData = useMemo(() => {
        const chaptersData =  data.allMarkdownRemark.edges.filter(
            ({ node }) => node.frontmatter.type === 'chapter'
        )

        const chapters = chaptersData.map( ({node}) => {
            const tasks = data.allMarkdownRemark.edges.filter((entry) => entry.node.frontmatter.chapter_id === node.frontmatter.id)
            tasks.sort((a,b) => {
                if (a.node.frontmatter.index === null || b.node.frontmatter === null)
                    return true

                return a.node.frontmatter.index - b.node.frontmatter.index
            })
            node["tasks"] = tasks
            return node
        })

        chapters.sort(function(a, b) {
            if (a.frontmatter.index === null || b.frontmatter.index === null)
                return true;

            return a.frontmatter.index - b.frontmatter.index;
        });
        return chapters
    }, [])

    // Build a list of all the course content with the correct answers for the exercises
    const completeCourseContent = useMemo(() => {
        let courseContent = {}
        let chIndex = 1
        for (const chapter of courseData) {
            let module = {
                "moduleId": chapter.frontmatter.id,
                "moduleTitle": `kap${chIndex++}`
            }

            let sections = {}
            let secIndex = 1
            for (const task of chapter.tasks) {
                let section = {
                    "sectionId": task.node.frontmatter.id,
                    "sectionTitle": `avsn${secIndex++}`
                }


                // Add properties and the correct answer for every exercise
                let exercises = {}
                let exIndex = 1
                const exercisesData = task.node.htmlAst.children.filter(exercise => exercise.tagName === "exercise")
                for (const exercise of exercisesData) {
                    let _exercise = {
                        "exerciseId": exercise.properties.id,
                        "exerciseTitle": `upgft${exIndex++}(${exercise.properties.title})`
                    }

                    if("skipcomplete" in exercise.properties) {
                        for (const el of exercise.children) {
                            switch(el.tagName) {
                                case "choice":
                                case "multichoice":
                                    _exercise["type"] = el.tagName
                                    let correctAnswer = []

                                    /* Other elements than 'opt' (e.g. text) can be included in children,
                                    which is why we need to keep an offset */
                                    let offset = 0
                                    for (let i = 0; i < el.children.length; i++) {
                                        let ans = el.children[i]
                                        if (ans.tagName !== "opt") {
                                            offset++
                                            continue
                                        }
                                        if ("properties" in ans && "correct" in ans.properties)
                                            correctAnswer.push(i - offset)
                                    }
                                    _exercise["correctAnswer"] = correctAnswer
                                    break
                                case "exact-match":
                                case "numeric-match":
                                    _exercise["type"] = el.tagName
                                    _exercise["correctAnswer"] = el.properties.correct
                                    break
                                case "regex-match":
                                    _exercise["type"] = el.tagName
                                    _exercise["correctAnswer"] = el.properties.regex
                                    break
                                default:
                                    break
                            }
                        }
                    } else {
                        //_exercise["type"] = "mark-complete"
                        continue
                    }

                    exercises[_exercise.exerciseId] = _exercise
                }
                section["exercises"] = exercises
                sections[section.sectionId] = section
            }
            module["sections"] = sections
            courseContent[module.moduleId] = module
        }
        return courseContent
    }, [courseData])

    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
                <AdminPage courseInstanceId={courseInstanceId} courseContent={completeCourseContent} ></AdminPage>
            </Wrapper>
        </MsalProvider>
    )
}

export const pageQuery = graphql`
{
    site {
        siteMetadata {
            title
            courseInstanceId
        }
    }
        allMarkdownRemark {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        title
                        type
                        description
                        index
                        id
                        chapter_id
                    }
                    fields {
                        slug
                        fullPath
                    }
                }
            }
        }
    }
    `
