import React, {useEffect, useState} from 'react'
import { FaRegIdBadge } from 'react-icons/fa';
/* eslint-disable */
import classes from '../styles/admin-page.module.sass' // is used
/* eslint-enable */

import { useMsal } from '../utility/auth/msalContext';
import AdminPageTable from './admin-page-table';

var JSZip = require("jszip");
var FileSaver = require('file-saver');

const AdminPage = ({ courseInstanceId, courseContent }) => {
    const [userData, setUserData] = useState()
    const [userList, setUserList] = useState()
    const [progData, setProgData] = useState()
    const [user, setUser] = useState()
    
    const {getUsersFromApi, getUserResultsFromApi, isAuthenticated} = useMsal();

    // https://stackoverflow.com/questions/34156282/how-do-i-save-json-to-local-text-file
    function downloadToFile(content, fileName, contentType) {
        var a = document.createElement("a");
        var file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }
   
    function saveFile() {
        downloadToFile(progData, 'json.txt', 'text/plain');
    }

    async function download(e) { 
        e.preventDefault();    

        // MB [02/2024]: This was previously uncommented here, but commented out
        //               in ETE352. What does it do?
        //setUserData((JSON.stringify(await getUsersFromApi()).replace(/,/g, '\n') ))

        const data = await getUserResultsFromApi(user)
        const json = JSON.stringify(data, null, 2)
        setProgData(json)
    }

    async function getUserData(user){
        // Accept with @student.liu.se / @liu.se or not
        let user_full = user
        if  (!user.includes("@")){
            user_full = user_full + "@student.liu.se"
        }
        return JSON.stringify(await getUserResultsFromApi(user_full))
    }

    function handleChange(event) {
        setUser(event.target.value);
    }

    function handleUserListChange(event) {
        const userList = event.target.value.split("\n")
        setUserList(userList)
    }

    async function saveAllUsers(e) {
        e.preventDefault();

        const user_data = await Promise.all(userList.filter(
            user => (user.length > 0)).map(user => [user + ".json", getUserData(user)]))

        let zip = new JSZip();
        user_data.map(data_pair => zip.file(data_pair[0], data_pair[1]))
        zip.generateAsync({type:"blob"}).then(function(content) {
            // Uses FileSaver.js
            FileSaver.saveAs(content, "answers.zip");
        });
    }

    useEffect(() => {
        const fetchUsers = async () => {
            const userData = await getUsersFromApi()
            setUserData(userData)
        }

        if(isAuthenticated)
            fetchUsers()
    }, [isAuthenticated])


    return (
        <>
            <div className={classes.container}>
                <div className={classes.column}>
                <div>
                    <h2>Registered users:</h2>
                    <textarea readOnly value={userData ? userData.join("\n") : ""}>
                    </textarea>
                </div>
                <div>
                    <input type="text" placeholder="xxx123@liu.se" value={user || ""} onChange={handleChange}></input>
                    <button onClick={download}>
                        Fetch userdata
                    </button>

                    <button onClick={saveFile}>
                        Save userdata
                    </button>

                    <br></br>
                    <h2>Userdata:</h2>
                    <textarea readOnly value={progData}>

                    </textarea>
                </div>
            </div>
            <div className={classes.column}>
                <h2>Save data for users:</h2>
                <textarea onChange={handleUserListChange}>
                </textarea>
                <br></br>
                <button className={classes.savebtn} onClick={saveAllUsers}>
                    Save All
                </button>
            </div>
            </div>

            <div className={classes.overviewWrapper}>
                <h2>User assignments overview:</h2>
                <AdminPageTable 
                    courseInstanceId={courseInstanceId} 
                    courseContent={courseContent} 
                    userData={userData} 
                    getUserResultsFromApi={getUserResultsFromApi} 
                    downloadToFile={downloadToFile} />
            </div>
        </>
    )
}

export default AdminPage
