import React, {useEffect, useMemo, useState} from 'react'
/* eslint-disable */
import classes from '../styles/admin-page-table.module.sass' // is used
/* eslint-enable */

class UserData {
    #name               = undefined
    #exercises          = undefined
    #numCorrectAnswers  = undefined

    constructor(name, exerciseList) {
        this.#name = name
        this.#exercises = Array(exerciseList.length)
        for(let i = 0; i < exerciseList.length; ++i) {
            this.#exercises[i] = {
                "title": exerciseList[i].title,
                "status": false,
                "type": exerciseList[i].type,
                "data": undefined
            }
        }
    }

    insertExercise(title, status, type, data, index) {
        this.#exercises[index] = {
            "title": title,
            "status": status,
            "type": type,
            "data": data
        }
    }

    get numCorrectAnswers() {
        if(this.#numCorrectAnswers === undefined) {
            this.#numCorrectAnswers = this.#exercises.reduce((total, ex) => {
                if(ex.status)
                    return total + 1
                return total
            }, 0)
        }
        return this.#numCorrectAnswers
    }

    get allExercisesComplete() {
        if(this.#exercises.length === 0)
            return 0
        return Math.floor(this.numCorrectAnswers/this.#exercises.length)
    }

    get exercises() {
        return this.#exercises
    }

    set exercises(exerciseList) {
        this.#exercises = exerciseList
    }

    get name() {
        return this.#name
    }

    get asCsvArray() {
        return this.#exercises.map(ex => ex.status)
    }
}


const AdminPageTable = ({ courseInstanceId, courseContent, userData, getUserResultsFromApi, downloadToFile }) => {

    const [userTable, setUserTable] = useState([])
    const [visibleUserTable, setVisibleUserTable] = useState([])
    const [visibleExerciseList, setVisibleExerciseList] = useState([])
    const [selectedUserExercisePairs, setSelectedUserExercisePairs] = useState({})

    const [showLoader, setShowLoader] = useState(false)

    // search field state
    const [userSearchValue, setUserSearchValue] = useState("")
    const [exerciseSearchValue, setExerciseSearchValue] = useState("")
    const [exerciseFilterOn, setExerciseFilterOn] = useState("name")

    // sort field state
    const [doSortName, setDoSortName] = useState(true)
    const [doSortCompletion, setDoSortCompletion] = useState(false)
    const [doSortExercisesCompleted, setDoSortExercisesCompleted] = useState(false)


    const exerciseList = useMemo(() => {
        // Flatten the courseContent list and create the column headings
        let exerciseList = []
        for (const mod in courseContent) {
            let module = courseContent[mod]
            for(const sec in module.sections) {
                let section = module.sections[sec]
                for(const ex in section.exercises) {
                    let exercise = section.exercises[ex]
                    let title = `${module.moduleTitle}-${section.sectionTitle}-${exercise.exerciseTitle}`
                    exerciseList.push({
                        "title": title,
                        "type": exercise.type
                    })
                }
            }
        }
        setVisibleExerciseList(exerciseList)
        return exerciseList
    }, [])


    const extraColumnHeaders = useMemo(() => {
        return {
            "prepend": ["LIU-ID"],
            "append": ["Färdig?", `Totalt avklarade av ${visibleExerciseList.length}`]
        }
    }, [visibleExerciseList])


    const fetchAndProcessUserResults =
        (user) => 
            new Promise(async (resolve, reject) => {
        let result = await getUserResultsFromApi(user)
        if(result)
            resolve(result)
        else
            reject(`Could not fetch data from ${user}`)
        }).then((data) => {
            let userObj = new UserData(user.split('@')[0], exerciseList)

            for (const module of data.modules) {
                for (const section of module.sections) {
                    for (const exercise of section.exercises) {
                      // cc = course content
                      const ccModule = courseContent[module.moduleId]
                      const ccSection = ccModule.sections[section.sectionId]
                      const ccExercise = ccSection.exercises[exercise.exerciseId]
                      if(!ccExercise)
                          continue
                          
                      const userExerciseData = JSON.parse(exercise.exerciseData)
                      const title = `${ccModule.moduleTitle}-${ccSection.sectionTitle}-${ccExercise.exerciseTitle}`

                      userObj.insertExercise(
                          title,
                          userExerciseData.status === "complete",
                          ccExercise.type,
                          userExerciseData.data,
                          exerciseList.findIndex((ex) => ex.title == title)
                      )
                    }
                }
            }
            return userObj
        }, 
        (errorMsg) => {
            console.error(errorMsg)
        }
    )


    async function getUserAssignmentsResults() {
        setShowLoader(true)

        let promises = []
        for(const user of userData) {
            promises.push(fetchAndProcessUserResults(user))
        }
        let userTable = prioSortUserTable(await Promise.all(promises))

        setUserTable(userTable)
        setVisibleUserTable(userTable)
    }


    function exportToCSV(separator=';') {
        let csvFile = ""
        csvFile += `LIU-ID${separator}`
        for(const ex of exerciseList) {
            csvFile += `${ex.title}${separator}`
        }
        csvFile += `Färdig?${separator}Totalt avklarade av ${exerciseList.length}${separator}\n`

        for(const user of userTable) {
            csvFile += `${user.name}${separator}`
            for(const ex of user.exercises) {
                csvFile += `${ex.status ? '1' : '0'}${separator}`
            }
            csvFile += `${user.allExercisesComplete}${separator}${user.numCorrectAnswers}${separator}`
            csvFile += '\n'
        }
        downloadToFile(csvFile, `${courseInstanceId}_user_overview.csv`, "text/csv")
    }


    function filterUsers(users, value) {
        return users.filter((user) => {
          if(user.name.toLowerCase().includes(value.toLowerCase()))
              return user
        })
    }


    function searchUsers(input) {
        let doRefilter = false
        let searchTable = visibleUserTable

        // If user erases input, then we want to filter from original table
        if(input.length < userSearchValue.length)  {
            searchTable = userTable
            doRefilter = true
        }
        setUserSearchValue(input)

        let table = filterUsers(searchTable, input)
        if(doRefilter) {
            let filteredTables = filterExercises(table, exerciseList, exerciseSearchValue) // need to refilter exercises as well
            setVisibleExerciseList(filteredTables["exercises"])
            table = prioSortUserTable(filteredTables["users"])
        }
        setVisibleUserTable(table)
    }


    const typeFilter = (value) => {
      return (exercise) => {
          if(exercise.type.includes(value.toLowerCase()))
              return exercise
    }}

    const titleFilter = (value) => {
        return (exercise) => {
            if(exercise.title.toLowerCase().includes(value.toLowerCase()))
                return exercise
        }
    }
    
    function filterExercises(users, exercises, value) {
        let _filter = titleFilter(value)
        switch(exerciseFilterOn) {
            case "title":
                break
            case "type":
                _filter = typeFilter(value)
                break
        }
        return {
            "exercises": exercises.filter(_filter),
            "users": users.map(user => {
              let copy = new UserData(user.name, [])
              copy.exercises = user.exercises.filter(_filter)
              return copy
            })
        }
    }


    function searchExercises(input) {
        let userSearchTable = visibleUserTable
        let exerciseSearchTable = visibleExerciseList

        // If user erases input, then we want to filter from original table
        if(input.length < exerciseSearchValue.length) {
            exerciseSearchTable = exerciseList
            userSearchTable = prioSortUserTable(filterUsers(userTable, userSearchValue)) // need to refilter users in original table
        }
        setExerciseSearchValue(input)

        let filteredTables = filterExercises(userSearchTable, exerciseSearchTable, input)
        setVisibleExerciseList(filteredTables["exercises"])
        setVisibleUserTable(filteredTables["users"])
    }


    /**
     * Sort user table according to priority
     * 1. completion
     * 2. totalCompleted
     * 3. name
     * @param {UserData} table 
     * @returns sorted table
     */
    function prioSortUserTable(table) {
        return [...table].sort((userA, userB) => {
            return (doSortCompletion * (userB.allExercisesComplete - userA.allExercisesComplete))
                || (doSortExercisesCompleted * (userB.numCorrectAnswers - userA.numCorrectAnswers))
                || (doSortName * (userA.name.localeCompare(userB.name)))
        })
    }

    useEffect(() => {
        setVisibleUserTable(prioSortUserTable(visibleUserTable))
    }, [doSortName, doSortCompletion, doSortExercisesCompleted])
    

    return (
        userTable.length > 0 ?
        <div>
            {
                Object.keys(selectedUserExercisePairs).length > 0 ?
                <div>
                    {
                        Object.keys(selectedUserExercisePairs).map((key, index) => {
                            let title = selectedUserExercisePairs[key].title
                            let type = selectedUserExercisePairs[key].type
                            let status = selectedUserExercisePairs[key].status
                            let data = selectedUserExercisePairs[key].data !== undefined ? selectedUserExercisePairs[key].data : `${key} has not done this exercise yet.`
                            return <div className={classes.selected} key={index}>
                                <button onClick={() => {
                                    let copy = {}
                                    Object.assign(copy, selectedUserExercisePairs)
                                    delete copy[key]
                                    setSelectedUserExercisePairs(copy)
                                }}>Remove</button>
                                <h3>{key}</h3>
                                <table>
                                    <tbody>
                                        <tr><td><b>Exercise:</b></td><td>{title}</td></tr>
                                        <tr><td><b>Type:</b></td><td>{type}</td></tr>
                                        <tr><td><b>Status:</b></td><td>{status ? "completed" : "incomplete"}</td></tr>
                                        <tr><td><b>Data:</b></td><td>{data}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        })
                    }
                </div> : <></>
            }
            <div className={classes.searchFieldContainer}>
                <span>Search user: </span>
                <input type='text' onChange={(e) => searchUsers(e.target.value)}></input>

                <span style={{marginLeft: "6em"}}>Search exercise: </span>
                <input type='text' onChange={(e) => searchExercises(e.target.value)}></input>
                <span style={{marginLeft: "1em"}}>Filter on: </span>
                <select name="filter" onChange={(e) => setExerciseFilterOn(e.target.value)}>
                    <option value="title">Title</option>
                    <option value="type">Type</option>
                </select>
            </div>
            <div className={classes.sortFieldContainer}>
                <span>Sort by:</span>
                <input type="checkbox" 
                    defaultChecked={doSortName} 
                    onChange={(e) => setDoSortName(e.target.checked)}
                ></input><span>Name</span>
                <input type="checkbox"
                    onChange={(e) => setDoSortCompletion(e.target.checked)}
                ></input><span>Completion</span>
                <input type="checkbox"
                    onChange={(e) => setDoSortExercisesCompleted(e.target.checked)}
                ></input><span>Exercises completed</span>
            </div>
            <div className={classes.overviewTableContainer}>
                <table className={classes.userOverviewTable} cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            {
                                /* Create table header with first and (second-) last column fixed */
                                extraColumnHeaders.prepend.map((col, i) => {
                                    let classNames = [classes.headerColumn, classes.fixedColumn]
                                    if(i === 0)
                                        classNames.push(classes.firstColumn)
                                    return <th className={`${classNames.join(' ')}`} key={i}>{col}</th>
                                })

                            }
                            {
                                visibleExerciseList.map((ex, i) => {
                                    let classNames = [classes.headerColumn, classes.fixedColumn]
                                    return <th className={`${classNames.join(' ')}`} key={i}>{ex.title}</th>
                                })
                            }
                            {
                                extraColumnHeaders.append.map((col, i) => {
                                    let classNames = [classes.headerColumn, classes.fixedColumn]
                                    if (i === extraColumnHeaders.append.length - 1)
                                        classNames.push(classes.lastColumn)
                                    else if (i === extraColumnHeaders.append.length - 2) 
                                        classNames.push(classes.secondLastColumn)
                                    return <th className={`${classNames.join(' ')}`} key={i}>{col}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        
                        {
                            /* Create table body with first and (second-) last column fixed */
                            visibleUserTable.map((user, i) => <tr key={i}>
                                <td className={`${[classes.fixedColumn, classes.firstColumn].join(' ')}`}>{user.name}</td>
                                {
                                    user.exercises.map((exercise, i) => {
                                        let classNames = [classes.bodyColumn]
                                        // Decide cell color based on if statement is true or false.
                                        // True == Green cell, False == Yellow cell
                                        const cellColor = (stmnt) => stmnt ? classes.greenCell : classes.yellowCell
                                        classNames.push(cellColor(exercise.status))
                                        return <td 
                                            key={i} 
                                            className={`${classNames.join(' ')}`} 
                                            onClick={() => {
                                                let copy = {...selectedUserExercisePairs}
                                                copy[user.name] = exercise
                                                setSelectedUserExercisePairs(copy)
                                            }}
                                        >{exercise.status ? '1' : '0'}</td>
                                    })
                                }
                                <td className={`${[classes.fixedColumn, classes.secondLastColumn, user.allExercisesComplete ? classes.greenCell : classes.yellowCell].join(' ')}`}>{user.allExercisesComplete}</td>
                                <td className={`${[classes.fixedColumn, classes.lastColumn, user.allExercisesComplete ? classes.greenCell : classes.yellowCell].join(' ')}`}>{user.numCorrectAnswers}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
            <button onClick={() => exportToCSV()}>
                Export to csv
            </button>
        </div>
        : // else render this
        <div>
            <button onClick={() => getUserAssignmentsResults()}>
                Fetch data
            </button>
            {showLoader && <div className={classes.loader}></div>}
        </div>
    )
}

export default AdminPageTable
